<template>
  <el-dialog v-model="dialogVisible" width="812px" @close="closeDialog">
    <div class="content">
      <!-- 立即联系 -->
      <div class="imgIcon">
        <img src="../assets/kfimg.png" alt="">
      </div>
      <div class="text">
        客服咨询热线
      </div>
      <div class="number">
        {{ HomeAbout.ContactPhone }}
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, defineExpose, onMounted } from 'vue'
// 引入请求Api
import { GetHomeAbout } from '../utils/api'
const dialogVisible = ref(false) //默认关闭
// 关闭
const closeDialog = () => {
  dialogVisible.value = false
}
// 打开
const isOpen = () => {
  dialogVisible.value = true
}
const HomeAbout = ref({})

onMounted(() => {
  // 加载配置文件
  // fetch('/config.json')
  //   .then(response => response.json())
  //   .then(config => {
  //     // 使用配置项
  //     console.log(config.apiEndpoint);
  //     console.log(config.apiKey);
  //     number.value = config.apiKey
  //     // ...
  //   })
  //   .catch(error => {
  //     console.error('Failed to load config file:', error);
  //   });

   // 关于我们
   GetHomeAbout().then(res => {
    console.log('关于我们=>', res)
    if (res.data.code == 200) {
      HomeAbout.value = res.data.data
    }
  })
})


// 暴露方法
defineExpose({
  isOpen
})

</script>

<style scoped lang="less">
.content {
  height: 100%;
  width: 100%;
  // background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;

  .imgIcon {
    margin-top: 13px;
    width: 184px;
    height: 184px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .text {
    margin: 41px 0px 31px 0px;
    font-size: 26px;
    font-family: PingFang SC;
    color: #999999;
  }

  .number {
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #03102C;
    margin-bottom: 43px;
  }
}
</style>
