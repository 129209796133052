<template>
  <div class="swiper-case">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in CaseCenterData" :key="index" @click="goCaseDetail(item.Id)">
        <div style="width: 400px;height: 250px;overflow: hidden;">
          <img :src="item.ImgUrl" alt="" width="100%" height="100%">
        </div>
        <div class="s-text">
         {{ item.Title }}
        </div>
        <div class="s-text1">
          {{ item.SubTitle }}
        </div>
      </div>
    </div>


  </div>
  <div class="swiper-button-next case-next"></div>
  <div class="swiper-button-prev case-prev"></div>
</template>

<script setup>
// 引入vue函数
import { onMounted, defineProps, ref,watch } from 'vue';

// 引入轮播图js文件
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import router from '@/router';
// 接收数据
const props = defineProps(['CaseCenterData'])
let num = ref(3)
watch(() => props.CaseCenterData, (newValue, oldValue) => {
  if (newValue.length < 3) {
    num.value = newValue.length
  }else{
    num.value = 3
  }
})


// 初始化轮播图
const bannerCase = () => {
  setTimeout(() => {
    var mySwiper3 = new Swiper('.swiper-case', {
      loop: num.value==3, // 循环模式选项
      loopPreventsSlide: false,
      // speed: 1000,

      slidesPerView: 3,
      spaceBetween: 30,
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".case-next",
        prevEl: ".case-prev"
      },

    });
  }, 500);
}


onMounted(() => {
  // 获取轮播图数据
  bannerCase()
})

const goCaseDetail=(caseId)=>{
  console.log(caseId)
    router.push({path:'/case',query:{caseId}})
}
</script>


<style scoped lang="less">
// 轮播图
.swiper-case {
  // background-color: red;
  height: 486px;
  width: 100%;
  overflow: hidden;
  padding: 5px;
}


.swiper-button-next,
.swiper-button-prev {
  width: 44px;
  height: 48px;
  background: #000000;
  opacity: 0.2;
  color: rgba(255, 255, 255, 1);
}

.swiper-button-next {
  position: absolute;
  right: 0px;
}


.carousel-img {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  cursor: pointer;
  img{
    transition: all 0.5s;
    // object-fit:contain!important;

  }
  img:hover{
          transform: scale(1.1);
        }
  .s-text {
    width: 351px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #14304C;
    line-height: 22px;
    margin-top: 20px;
height: 46px;
    overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
  }

  .s-text1 {
    width: 360px;
height: 130px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
    line-height: 26px;
    margin-top: 20px;
    overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;

  }

  text-align: left;
  font-size: 18px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(158, 158, 158, 0.26);
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  // object-fit: cover;

}</style>
