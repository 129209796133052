import request from '../request.js'

const Api = {
  getBanners:'api/HomePage/GetBanners',//轮播图
  getHomeProductByType:'api/HomePage/GetHomeProductByType',//产品中心
  getCaseCenter:'api/HomePage/GetCaseCenter',// 案例中心
  getNewCenter:'api/HomePage/GetNewCenter',//新闻中心
  getHomeAbout:'api/HomePage/GetHomeAbout',//关于我们
  getCaseTypes:'api/Case/CaseTypes',//案例种类
  getCaseList:'api/Case/GetCaseList',//案例集合
  getCaseDetails:'api/Case/GetCaseDetails',//案例详情
  getNewTypes:'api/News/NewTypes',//新闻种类
  getNewsPageList:'api/News/GetNewsPageList',//新闻集合(分页)
  getNewDetails:'api/News/GetNewDetails',//新闻详情
  getProductTypeDisplay:'api/Product/ProductTypeDisplay',//产品种类
  getProductList:'api/Product/ProductList',//产品列表
  getProductDetail:'api/Product/ProductDetail',//产品详情
  addRequirement:'api/HomePage/AddRequirement',//免费回电
  getHuoBan:'api/HomePage/GetHuoBan',//合作伙伴
  getZhengShu:'api/HomePage/GetZhengShuList',
  getHeZuoType:'api/Saas/GetHeZuoType', //合作方式
  getLandlordIntroduce:'api/Saas/GetLandlordIntroduce', //获取房东版介绍
  getRenterIntroduce:'/api/Saas/GetRenterIntroduce', //获取租客版介绍
  getOurAdvantages:'api/Saas/GetOurAdvantages', //获取我们的优势
  getIotDevices:'api/Saas/GetIotDevices', //获取智能设备
  getMerchants:'api/Saas/GetMerchants', //获取已入驻
  getLandlordQA:'api/Saas/GetLandlordQA', //房东问题和解决
  getRenterQA:'api/Saas/GetRenterQA', //租客问题和解决


}

// 获取轮播图
export const GetBanners = ()=> request({url: Api.getBanners,method: "GET"})
//产品中心
export const GetHomeProductByType = (params)=> request({url: Api.getHomeProductByType,method: "GET",params})
// 案例中心
export const GetCaseCenter = ()=> request({url: Api.getCaseCenter,method: "GET"})
//新闻中心
export const GetNewCenter = ()=> request({url: Api.getNewCenter,method: "GET"})
//关于我们
export const GetHomeAbout = ()=> request({url: Api.getHomeAbout,method: "GET"})
//案例集合
export const GetCaseList = (params)=> request({url: Api.getCaseList,method: "GET",params})
//案例详情
export const GetCaseDetails = (params)=> request({url: Api.getCaseDetails,method: "GET",params})
//新闻种类
export const GetNewTypes = ()=> request({url: Api.getNewTypes,method: "GET"})
//新闻集合(分页)
export const GetNewsPageList = (params)=> request({url: Api.getNewsPageList,method: "GET",params})
//新闻详情
export const GetNewDetails = (params)=> request({url: Api.getNewDetails,method: "GET",params})
//产品种类
export const GetProductTypeDisplay = ()=> request({url: Api.getProductTypeDisplay,method: "GET"})
//产品列表
export const GetProductList = (params)=> request({url: Api.getProductList,method: "GET",params})
//产品详情
export const GetProductDetail = (params)=> request({url: Api.getProductDetail,method: "GET",params})
//免费回电
export const AddRequirement = (data)=> request({url: Api.addRequirement,method: "POST",data})
// 合作伙伴
export const GetHuoBan = (params)=> request({url: Api.getHuoBan,method: "GET",params})
// 案例中心种类
export const GetCaseTypes = ()=> request({url: Api.getCaseTypes,method: "GET"})
// 荣誉资质
export const GetZhengShu = (params)=> request({url: Api.getZhengShu,method: "GET",params})

// SAAS平台
//合作方式
export const GetHeZuoType = (params)=> request({url: Api.getHeZuoType,method: "GET",params})
//获取房东版介绍
export const GetLandlordIntroduce = (params)=> request({url: Api.getLandlordIntroduce,method: "GET",params})
//获取租客版介绍
export const GetRenterIntroduce = (params)=> request({url: Api.getRenterIntroduce,method: "GET",params})
//获取我们的优势
export const GetOurAdvantages = (params)=> request({url: Api.getOurAdvantages,method: "GET",params})
//获取智能设备
export const GetIotDevices = (params)=> request({url: Api.getIotDevices,method: "GET",params})
//获取已入驻
export const GetMerchants = (params)=> request({url: Api.getMerchants,method: "GET",params})
//房东问题和解决
export const GetLandlordQA = (params)=> request({url: Api.getLandlordQA,method: "GET",params})
//租客问题和解决
export const GetRenterQA = (params)=> request({url: Api.getRenterQA,method: "GET",params})








