<template>
  <div id="box">
    <div class="content">
      <nav>
        <!-- NAV导航栏 -->
        <div class="navTitle">
          <ul>
            <div>
              <img src="./assets/gsLogo.png" alt="" style="width:64px;height: 64px;">
              <p style="font-size: 20px;font-family: PingFang SC;font-weight: 800;width: 250px;">安徽金金科技信息有限公司 </p>
            </div>
            <li v-for="(item, index) in navTitles" :key="index" @click="isActive(index, item.path)"
              @mouseenter="enter(index)" @mouseleave="leave(index)">
              <p>{{ item.name }}</p>
              <div class="ollist">
                <transition name="fade">
                  <ol v-show="index == 2 && menuShow">
                    <li v-for="(items, i) in ProductTypeDisplay" :key="i" @click.stop="goProduct(items.Id)">{{
              items.Name
            }}</li>
                  </ol>
                </transition>
              </div>
              <!-- //注意需要加入KEY值 否则报错 -->
            </li>
          </ul>
        </div>
      </nav>
      <!-- 主体 -->
      <main>
        <el-config-provider :locale="Goto">
          <router-view />
        </el-config-provider>
      </main>
      <footer>
        <div class="f-content">
          <div class="f-title">
            <ul>
              <li v-for="(item, index) in navTitles" :key="index" @click="isActive(index, item.path)">{{ item.name }}
              </li>
            </ul>
          </div>
          <div class="f-msg">
            <p>商务合作：{{ HomeAbout.ContactName }} {{ HomeAbout.ContactPhone }}</p>
            <p>公司地址：{{ HomeAbout.Address }}</p>
            <p>
              <a href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration:none"><span
                  style="color: #fff;">{{
              HomeAbout.RecordInfo
            }}</span></a>
            </p>
          </div>
        </div>
        <div style="display: flex;">
          <div class="f-logo">
            <div style="width: 112px;height: 112px;">
              <img src="./assets/wxCode.png" alt="">
            </div>
            <div style="font-size: 18px;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;">
              微信小程序
            </div>
          </div>
          <div class="f-logo">
            <div style="width: 112px;height: 112px;">
              <img src="./assets/vxCode1.png" alt="">
            </div>
            <div style="font-size: 18px;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;">
              微信公众号
            </div>
          </div>
        </div>
      </footer>
      <!-- 免费回电 -->
      <callBack ref="isOpen"></callBack>
      <!-- 立即联系 -->
      <contactImmediately ref="isOpens"></contactImmediately>
    </div>
    <div class="fied">
      <div class="icon-number">
        <div @click="clickContact">
          <img src="./assets/numberIcon.png" alt="">
          <p>立即联系</p>
        </div>
        <div @click="clickCallBack">
          <img src="./assets/openIcon.png" alt="">
          <p>免费回电</p>
        </div>
      </div>
      <div class="icon-button" v-if="false">
        <div>
          <img src="./assets/customerIcon.png" alt="">
        </div>
        <div class="customer">
          在线客服
        </div>
      </div>
    </div>
  </div>
  <!-- 定位 -->
</template>

<script setup>
import Goto from 'element-plus/dist/locale/zh-cn.mjs'
// 引入vue函数
import { onMounted, ref, watch, nextTick } from 'vue';
// 引入路由
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()
// 引入请求Api
import { GetHomeAbout, GetProductTypeDisplay } from './utils/api'


// 引入免费回电
import callBack from './components/callBack.vue';
// 引入立即联系
import contactImmediately from './components/contactImmediately.vue';


// 免费回电
const isOpen = ref();
const clickCallBack = () => {
  isOpen.value.isOpen()
}
// 立即联系
const isOpens = ref();
const clickContact = () => {
  isOpens.value.isOpen()
}

// 生成NAV导航栏数据
const navTitles = [
  { path: '/', name: '首页' },
  { path: '/saas', name: '规谷通' },
  { path: '/product?isShow=true', name: '产品展示' },
  { path: '/case?isShow=true', name: '客户案例' },
  { path: '/news?isShow=true', name: '新闻资讯' },
  { path: '/contact', name: '关于我们' },
  { path: '/onlinemsg', name: '在线留言' },
  // { name: 'APP下载' },
]

// 轮播图数据
const activeIndex = ref()
const mouseIndex = ref()
let ProductTypeDisplay = ref()


const menuShow = ref(false)

// watch(() => router.currentRoute.value.path, (toPath) => {
//   console.log('toPath=>', toPath)
//   if (toPath == '/') {
//     activeIndex.value = 0
//   } else if (toPath == '/product' || toPath == '/productdetails') {
//     activeIndex.value = 1
//   } else if (toPath == '/news/newslist' || toPath == '/news/newsdetails') {
//     activeIndex.value = 2
//   } else if (toPath == '/case' || toPath == '/casedetails') {
//     activeIndex.value = 3
//   } else if (toPath == '/contact') {
//     activeIndex.value = 4
//   }
// }, { immediate: true, deep: true })


const isActive = (index, path) => {
  // console.log(index)
  if (path) {
    router.push(path)
    activeIndex.value = index
  } else {
    console.log('我是下载');
    downloadFile('http://www.aggsg.com/Resource/ApiFile/apk/app_fd_0822.apk');
  }
}
const downloadFile = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = url.split('/').pop(); // 提取文件名
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
const goProduct = (itemId) => {
  console.log(itemId)
  router.push({ path: '/product', query: { itemId } })

}
const enter = (index) => {
  mouseIndex.value = index
  if (index == 2) {
    console.log('123')
    menuShow.value = true
  }
}
const leave = () => {
  console.log('1223')
  menuShow.value = false
}

const HomeAbout = ref({})

onMounted(() => {
  // 关于我们
  GetHomeAbout().then(res => {
    console.log('关于我们=>', res)
    if (res.data.code == 200) {
      HomeAbout.value = res.data.data
    }
  })
  // 种类
  GetProductTypeDisplay().then(res => {
    ProductTypeDisplay.value = res.data.data
    console.log(ProductTypeDisplay.value, 'ProductTypeDisplay.value')
  })

})

</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  // overflow-y: hidden;
  // overflow: auto;

}

#box {
  max-width: 1920px;
  position: relative;
  margin: auto;
}


.mTop {
  margin-top: 200px;
}

.fade-enter-active {
  animation: expand-contract 0.5s ease;
}

.fade-leave-active {
  animation: expand-contract 0.5s ease reverse;
}

// 收缩展开动画
@keyframes expand-contract {
  0% {
    overflow: hidden;
    opacity: 0;
    // max-height: 0px;
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0px);
    // max-height: 240px; // 大于等于下拉菜单的高度
    opacity: 1;
  }
}



a.router-link-exact-active {
  color: #FFFFFF !important;
}


.content {
  max-width: 1920px;
}

// 局部滚动隐藏滚动条
.content::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

nav {
  width: 100%;
  margin: auto;
  position: absolute;
  z-index: 999;

  a {
    font-size: 18px;
    color: #FFFFFF;
    margin-left: 96px;
    text-decoration: none;

    &.router-link-exact-active {
      color: #007FFF;
    }
  }
}

.navTitle:hover {
  color: #333333 !important;
  background-color: #ffffff;
  border: none;
}

// 导航跳转
.navTitle {
  max-width: 1920px;
  height: 76px;
  display: flex;
  align-items: center;
  // z-index: 999;
  border-bottom: 1px solid rgba(255, 255, 255, .2);
  font-size: 18px;
  color: #ffffff;

  ul {
    padding: 0px 304px 0px 320px;
    max-width: 1296px;

    height: 76px;
    display: flex;
    list-style: none;
    align-items: center;
    // justify-content: space-between;

    div {
      margin-right: 90px;
      font-size: 48px;
      font-family: PingFang SC;
      font-weight: 800;
      // color: #FFFFFF;
      cursor: pointer;
      display: flex;
      align-items: center;
    }


    li {
      cursor: pointer;
      height: 76px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;

      .ollist {
        // background-color: red;
        height: 240px;
        list-style: none;
        position: absolute;
        top: 76px;
        right: 38.8%;

        // display: none;
        ol {
          height: 240px;
          //overflow-y: auto;
        }

        ol::-webkit-scrollbar {
          display: none;
          width: 0;
          height: 0;
        }


        li {
          height: 59px;
          width: 120px;
          padding: 0px 0px 0px 0px;
          list-style: none;
          border-bottom: 1px solid #EDEDED;
          background: #FFFFFF;
          font-size: 16px;
          color: #161616;
          // display: flex;
          // align-items: center;
          // justify-content: space-between;
          cursor: pointer;
          font-weight: 500;
        }

        li:hover {
          color: #338DE7;
          background: #EEF2F8;
        }
      }
    }

    .active {
      color: #007FFF;
      font-weight: bold;
    }

    li:hover {
      // background-color: rgba(0, 127, 255, 1);
      background-color: rgb(6, 88, 195);
      color: white;

      // ol {
      //   display: block;
      // }
    }
  }
}



// main
main {
  max-width: 1920px;
  margin: auto;
  // background-color: green;
  // padding-top: 109px;
}

// footer
footer {
  margin: auto;
  max-width: 1280px;
  height: 138px;
  background: #14304C;
  padding: 47px 320px;
  display: flex;
  justify-content: space-between;

  .f-content {
    color: rgba(255, 255, 255, 1);
    text-align: left;

    .f-title {
      ul {
        list-style: none;
        display: flex;
        padding-bottom: 17px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      }

      ul li:first-child {
        padding-left: 0px;
      }

      ul li:last-child {
        padding-right: 0px;
        border: none;
      }

      ul li {
        padding: 0px 23px;
        font-size: 16px;
        line-height: 16px;
        border-right: 1px solid rgba(255, 255, 255, 0.3);
      }

      ul li:hover {
        color: #007FFF;
        cursor: pointer;
      }
    }

    .f-msg {
      p {
        margin-top: 20px;
        font-size: 14px;
        line-height: 14px;
        font-family: PingFang SC;
      }
    }
  }

  .f-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

// 客服定位
.fied {
  z-index: 999;
  // background-color: black;
  position: fixed;
  right: 0px;
  top: 50%;

  .icon-number {
    width: 56px;
    height: 134px;
    padding: 32px 10px;
    background-color: #FFFFFF;
    float: right;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 0px 10px 0px rgba(26, 68, 110, 0.28);

    div {
      cursor: pointer;
    }

    p {
      margin-top: 9px;
      line-height: 14px;
      font-size: 14px;
      font-family: PingFang SC;
      color: #999999;
    }
  }

  .icon-button {
    position: absolute;
    right: 0px;
    top: 50%;
    width: 122px;
    height: 50px;
    border-radius: 10px;
    background-color: rgb(6, 88, 195);
    padding: 0px 30px;
    clear: right;
    float: right;
    margin-top: 220px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;


    .icon {
      width: 28px;
      height: 30px;
    }

    .customer {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
}
</style>
