<template>
  <div class="swiper-home" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="700"
    data-aos-delay="100">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in bannersData" :key="index">
        <img class="carousel-img" :src="item.imgUrl" alt="">
        <!-- <div v-if="index == 0" class="on-download" @click="handleDownLoad">点击下载</div> -->
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination home-pagination"></div>
    <div class="swiper-button-next home-next"></div>
    <div class="swiper-button-prev home-prev"></div>
  </div>
</template>

<script setup>
// 引入vue函数
import { onMounted, defineProps, ref } from 'vue';

// 引入轮播图js文件
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
// 接收数据
const props = defineProps(['bannersData'])

const handleDownLoad = () => {
  console.log('我点击了下载');

}

// 初始化轮播图
const banner = () => {
  setTimeout(() => {
    var mySwiper1 = new Swiper('.swiper-home', {
      loop: true, // 循环模式选项
      loopPreventsSlide: false,
      // speed: 1000,
      autoplay: true,
      delay: 2000,
      // 如果需要分页器
      pagination: {
        el: ".home-pagination",
        clickable: true,
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".home-next",
        prevEl: ".home-prev"
      },

    });
  }, 500);
}


onMounted(() => {
  // 获取轮播图数据
  banner()
})
</script>


<style scoped lang="less">
.swiper-slide {
  position: relative;

  .on-download {
    position: absolute;
    top: 100px;
    left: 200px;
    background-color: red;
  }
}

// 轮播图
.swiper-home {
  // background-color: red;
  height: 740px;
  width: 100%;
  overflow: hidden;
}

.swiper-home:hover {

  .swiper-button-next,
  .swiper-button-prev {
    display: block;
  }
}

.swiper-button-next,
.swiper-button-prev {
  width: calc(var(--swiper-navigation-size)/ 44 * 270);
  display: none;
}

.carousel-img {
  width: 100%;
  height: 100%;
}
</style>
