import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入轮播图css
import 'swiper/css/swiper.min.css'
// 引入element
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
/* 引入aos动画库相关文件 */
import AOS from 'aos'
import 'aos/dist/aos.css'

import 'animate.css';


/* aos动画初始化 */
AOS.init({

});





createApp(App).use(AOS).use(ElementPlus).use(store).use(router).mount('#app')


