<template>
  <el-dialog v-model="dialogVisible" width="812px" @close="closeDialog">
    <div class="content">
      <!-- 免费回电 -->
      <div class="content-box">
        <el-row :gutter="20" data-aos="fade-right" data-aos-easing="ease" data-aos-duration="700"
            style="line-height: 30px;display: flex;justify-content: space-between;width: 525px;margin: 0 0 20px 0;">
            <span>您的姓名</span>
            <el-input v-model="formData.userName" placeholder="请输入姓名" required />
          </el-row>
          <el-row :gutter="20" data-aos="fade-left" data-aos-easing="ease" data-aos-duration="700"
            style="line-height: 30px;display: flex;justify-content: space-between;width: 525px;margin: 0 0 20px 0;">
            <span>联系电话</span>
            <el-input v-model="formData.phoneNo" placeholder="请输入号码" />
          </el-row>
          <el-row :gutter="20" data-aos="fade-right" data-aos-easing="ease" data-aos-duration="700"
            style="line-height: 30px;display: flex;justify-content: space-between;width: 525px;margin: 0 0 20px 0;">
            <span>电子邮箱</span>
            <el-input v-model="formData.email" placeholder="请输入邮箱" />
          </el-row>
          <el-row data-aos="fade-left" data-aos-easing="ease" data-aos-duration="700" :gutter="20"
          style="line-height: 30px;display: flex;justify-content: space-between;width: 525px;margin: 0;">
          <span>留言内容</span>
          <el-input resize="none" v-model="formData.content" type="textarea" placeholder="请输入留言内容" />
        </el-row>
      </div>
      <div class="callback" @click="freeNmuber" style="cursor: pointer;">
        提交
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, defineExpose, reactive,onMounted } from 'vue'
import { AddRequirement } from '../utils/api'
import { ElMessage } from 'element-plus'
const dialogVisible = ref(false) //默认关闭
// 关闭
const closeDialog = () => {
  dialogVisible.value = false
  formData.userName = ''
  formData.phoneNo = ''
  formData.email = ''
  formData.content = ''
}
// 打开
const isOpen = () => {
  dialogVisible.value = true
}

let formData = reactive({})


const freeNmuber = () => {
  console.log(formData)
  if (window.sessionStorage.getItem('isFrequently')=='true') {
    console.log(window.sessionStorage.getItem('isFrequently'),'0927')
    ElMessage({
      message: '提交频繁',
      type: 'error',
    })
  } else {
    AddRequirement(formData).then(res => {
      console.log(res)
      if (res.data.msg == '保存成功') {
        ElMessage({
          message: res.data.msg,
          type: 'success',
        })
        reset()
        window.sessionStorage.setItem('isFrequently','true')
        setTimeout(() => {
          window.sessionStorage.setItem('isFrequently','false')
        }, 60000)
      } else {
        ElMessage({
          message: res.data.msg,
          type: 'error',
        })
      }
    })
  }
}


// 暴露方法
defineExpose({
  isOpen
})

</script>

<style scoped lang="less">
.content {
  height: 100%;
  width: 100%;
  // background-color: red;


.el-input {
  width: 448px;
  height: 48px;
}

  .el-textarea {
        width: 448px;
        height: 170px;
      }

      ::v-deep .el-textarea__inner {
        min-height: 170px !important;
        width: 448px;
      }


  .content-box {
    width: 100%;
    // height: 88px;
    margin: 80px 0px;
    // background-color: yellowgreen;
    display: flex;
    flex-direction: column;
     align-items: center;

    .name {
      p {
        font-size: 16px;
        font-family: PingFang SC;
        color: #999999;
        text-align: left;
      }

      input {
        width: 352px;
        height: 60px;
        margin-top: 13px;
        background-color: #F5F5F5;
        outline: none;
        border: none;
        padding-left: 10px;
      }
    }

    .number {
      p {
        font-size: 16px;
        font-family: PingFang SC;
        color: #999999;
        text-align: left;
      }

      input {
        width: 352px;
        height: 60px;
        margin-top: 13px;
        background-color: #F5F5F5;
        outline: none;
        border: none;
        padding-left: 10px;
      }
    }
  }

  .callback {
    width: 352px;
    height: 60px;
    background: #007FFF;
    border-radius: 10px;
    line-height: 60px;
    margin: 110px auto 58px;
    font-size: 16px;
    font-family: PingFang SC;
    color: #FFFEFE;
  }
}
</style>
