<template>
  <div id="home">
    <div>
      <!-- Swiper轮播图 -->
      <HomeSwiper :bannersData="bannersData"></HomeSwiper>
    </div>
    <!-- 搜索 -->
    <!-- <div id="search">
      <div class="left-list">
        <ul>
          <li style="height: 24px;width: 24px;"><img src="../assets/search1.png" alt=""></li>
          <li style="margin: 0px 47px 0px 9px;">热门关键词:</li>
          <li class="zl-list" v-for="(item, index) in ProductTypeDisplay" :key="index">{{ item.Name}}</li>
        </ul>
      </div>
      <div class="right-search">
        <input type="text" placeholder="请输入关键词">
        <div style="padding:12px 0px;">
          <Search style="width: 20px; height: 20px;" />
        </div>
      </div>
    </div> -->

    <!-- 产品中心 -->
    <div id="product">
      <div class="titles" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="700">
        <div style="width: 4px;height: 28px;background: #0658C3;margin-right: 13px;"></div>
        <div>产品中心</div>
      </div>
      <div class="product-list">
        <!-- 一级头部 -->
        <div class="proTop" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="700">
          <!-- <div class="name">{{ item.ProductType }}</div> -->
          <!-- <div class="icon" @click="goProductMore(item.Id)">
            <ArrowRight />
          </div> -->
          <ul>
            <li :class="productTable == index ? 'productActive' : ''" v-for="(item, index) in ProductTypeDisplay" :key="index"
              @click="getProductList(item.Id, index)">{{ item.Name }}</li>
            <li @click="$router.push('/product')">查看更多>></li>
          </ul>
        </div>
        <div class="list" data-aos="fade-left" data-aos-easing="ease" data-aos-duration="700" v-if="isRefresh">
          <ProductSwiper :ProductHomeData="ProductHomeData" :indexTable="indexTable"></ProductSwiper>
        </div>
      </div>
    </div>

    <!-- 立即资讯 -->
    <div class="consult">
      <div class="titleText">
        <div data-aos="fade-right" data-aos-easing="ease" data-aos-duration="700" style="font-size: 40px;color:#FFFFFF;font-weight: bold;font-family: PingFang SC;
line-height: 36px;">智慧科技平台及服务</div>
        <div data-aos="fade-right" data-aos-easing="ease" data-aos-duration="700" style="font-size: 30px;color:#FFFFFF;font-family: PingFang SC;
line-height: 36px;">物联网软件定制</div>
      </div>
      <div class="button" data-aos="fade-right" data-aos-easing="ease" data-aos-duration="700" style="cursor: pointer;"  @click="clickContact">
        <span>立即咨询</span>
      </div>
    </div>

    <!-- 案例中心 -->
    <div id="case">
      <div class="titles" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="700">
        <div style="width: 4px;height: 28px;background: #0658C3;margin-right: 13px;"></div>
        <div>客户案例</div>
      </div>
      <div class="case-list">
        <!-- 一级头部 -->
        <div class="list" data-aos="fade-right" data-aos-easing="ease" data-aos-duration="700">
          <CaseSwiper :CaseCenterData="CaseCenterData"></CaseSwiper>
        </div>

      </div>
      <div style="float: right;margin-right: 65px;" @click="$router.push('/case')" class="caseHover">
        查看更多>>
      </div>
    </div>
    <!-- 新闻中心 -->
    <div id="news">
      <div class="titles" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="700">
        <div style="width: 4px;height: 28px;background: #0658C3;margin-right: 13px;"></div>
        <div>新闻资讯</div>
      </div>
      <div class="news-list">
        <div class="list">
          <!-- --------------------- -->
          <div class="newslist-content" v-for="(item, index) in NewCenter" :key="index" data-aos="fade-up"
            data-aos-easing="ease" data-aos-duration="700">
            <div class="n-title" >
              {{ item.NewType }}
            </div>
            <div class="n-content" v-if="item.NewList.length > 0">
              <div style="display: flex;margin: 18px 0px;cursor: pointer;" @click="goNewsDetails(item.NewList[0].Id)">
                <div style="width: 216px;height: 124px;z-index:999;">
                  <img :src="item.NewList[0].MainImgUrl">
                </div>
                <div style="margin-left:28px;">
                  <p class="title" style="margin-top: 25px;">{{ item.NewList[0].Title }} </p>
                  <p class="title1" style="margin-top: 19px;width: 351px;">{{ item.NewList[0].SubTitle }}</p>
                </div>
              </div>
              <!-- 列表2.。。 -->
              <div v-for="(items, indexs) in item.NewList.slice(1, 4)" :key="indexs"
                style="cursor: pointer;display: flex;align-items: center;height: 72px;justify-content: space-between;border-top:1px dashed rgba(220, 220, 220, 1);"
                @click="goNewsDetails(items.Id)" class="newsActive">
                <p class="title">{{ items.Title }}</p>
                <p class="title1" style="font-size: 14px;">{{ items.PublishTime }}</p>
              </div>
            </div>
            <div v-else style="font-size: 38px;padding: 50px 50px;">
              暂无新闻
            </div>
          </div>

        </div>
      </div>
      <div class="newsMore" style="float: right;margin-right: 65px;" @click="$router.push('/news')">
        查看更多>>
      </div>
    </div>

    <!-- 关于我们 -->
    <div id="about">
      <div class="titles" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="700">
        <div style="width: 4px;height: 28px;background: #0658C3;margin-right: 13px;"></div>
        <div>关于我们</div>
      </div>
      <div class="a-about">
        <div class="a-content" data-aos="fade-right" data-aos-easing="ease" data-aos-duration="700">
          <div class="a-title">
            {{ HomeAbout.CompanyName }}
          </div>
          <div class="a-text">
            <span v-html="HomeAbout.Introduction"></span>
          </div>
          <div class="a-detail" @click="$router.push('/contact')">
            了解详情 >>
          </div>
        </div>
        <div class="a-img" data-aos="fade-left" data-aos-easing="ease" data-aos-duration="700">
          <div class="icon-number">
            <div @click="$router.push({ path: '/contact', query: { indexTab: 0 } })">
              <img src="../assets/g-jj.png" alt="" style="width:30px;height:30px">
              <p>简介</p>
            </div>
            <div @click="$router.push({ path: '/contact', query: { indexTab: 1 } })">
              <img src="../assets/g-zz.png" alt="" style="width:30px;height:30px">
              <p>资质</p>
            </div>
          </div>
          <div class="icon-img" @click="$router.push('/contact')">
            <img :src="HomeAbout.ImgUrl" style="width:100%; height:100%;" alt="">
          </div>
        </div>
      </div>

    </div>

    <!-- 立即资讯1 -->
    <div class="consult1" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="700">
      <div class="titleText">
        <div data-aos="fade-right" data-aos-easing="ease" data-aos-duration="700" style="font-size: 40px;color:#FFFFFF;font-weight: bold;font-family: PingFang SC;
line-height: 36px;">智慧科技平台及服务</div>
        <div data-aos="fade-right" data-aos-easing="ease" data-aos-duration="700" style="font-size: 30px;color:#FFFFFF;font-family: PingFang SC;
line-height: 36px;">物联网软件定制</div>
      </div>
      <div class="button" style="cursor: pointer;" @click="clickContact">
        <span>立即咨询</span>
      </div>
    </div>

    <!-- 合作客户 -->
    <div id="cooperation">
      <div class="titles" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="700">
        <div style="width: 4px;height: 28px;background: #0658C3;margin-right: 13px;"></div>
        <div>合作客户</div>
      </div>
      <div class="cooperation-list">
        <!-- 一级头部 -->
        <div class="list" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="700">
          <CooperationSwiper :HuoBan="HuoBan"></CooperationSwiper>
        </div>
      </div>
    </div>

    <!-- 合作客户 -->
    <div id="message">
      <div class="titles" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="700">
        <div style="width: 4px;height: 28px;background: #0658C3;margin-right: 13px;"></div>
        <div>在线留言</div>
      </div>
      <div class="m-content">
        <div class="m-input" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="700">
          <el-input v-model="formData.userName" placeholder="姓名：" />
          <el-input v-model="formData.phoneNo" placeholder="联系电话：" />
          <el-input v-model="formData.email" placeholder="邮箱：" />
        </div>

        <el-input data-aos="fade-up" data-aos-easing="ease" data-aos-duration="700"
          style="margin: 20px 0px;min-height: 132px;" resize="none" v-model="formData.content" type="textarea"
          placeholder="输入内容" />
        <div class="m-button">
          <div class="reset" @click="reset" data-aos="fade-right" data-aos-easing="ease" data-aos-duration="700" style="cursor: pointer;">重置</div>
          <div class="submit" @click="freeNmuber" data-aos="fade-left" data-aos-easing="ease" data-aos-duration="700" style="cursor: pointer;">提交
          </div>
        </div>
      </div>
    </div>
       <!-- 免费回电 -->
       <contactImmediately ref="isOpens"></contactImmediately>
  </div>
</template>

<script setup>
// 引入vue函数
import { onMounted, ref, watch, nextTick, reactive } from 'vue';
// 引入请求Api
import { GetHomeProductByType, GetProductTypeDisplay, GetCaseCenter, GetNewCenter, GetHomeAbout, GetBanners, GetHuoBan, AddRequirement } from '../utils/api'
// 引入组件
import ProductSwiper from '../components/swiper/ProductSwiper.vue'
import CaseSwiper from '../components/swiper/CaseSwiper.vue'
import CooperationSwiper from '../components/swiper/CooperationSwiper.vue'
// 引入轮播图
import HomeSwiper from '../components/swiper/HomeSwiper.vue'
import { ElMessage } from 'element-plus'
// 引入立即联系
import contactImmediately from '../components/contactImmediately.vue';




// 引入路由
import { useRouter, useRoute } from 'vue-router'
import { ArrowRight, Search } from '@element-plus/icons-vue'
import AOS from 'aos'

const router = useRouter()
const route = useRoute()

// 轮播图数据
const bannersData = ref([])
const indexTable = ref(4)
const isRefresh = ref(true)

// 立即联系
const isOpens = ref();
const clickContact = () => {
  isOpens.value.isOpen()
}





// 在綫留言
let formData = reactive({})

const reset = () => {
  formData.userName = ''
  formData.phoneNo = ''
  formData.email = ''
  formData.content = ''
}

// 提交
// 数据是否频繁提交


const freeNmuber = () => {
  console.log('频繁操作',window.sessionStorage.getItem('isFrequently'))
  console.log(formData)
  if (window.sessionStorage.getItem('isFrequently')=='true') {
    console.log(window.sessionStorage.getItem('isFrequently'),'0927')
    ElMessage({
      message: '提交频繁',
      type: 'error',
    })
  } else {
    AddRequirement(formData).then(res => {
      console.log(res)
      if (res.data.msg == '保存成功') {
        ElMessage({
          message: res.data.msg,
          type: 'success',
        })
        reset()
        window.sessionStorage.setItem('isFrequently','true')
        setTimeout(() => {
          window.sessionStorage.setItem('isFrequently','false')
        }, 60000)
      } else {
        ElMessage({
          message: res.data.msg,
          type: 'error',
        })
      }
    })
  }

}

// 产品菜单
const ProductHomeData = ref()
const CaseCenterData = ref()
const NewCenter = ref()
const HomeAbout = ref({})
const HuoBan = ref({})



onMounted(() => {
  console.log('也没有触发')
  isRefresh.value = false
  // 刷新產品
   setTimeout(()=>{
    isRefresh.value = true
   },0)

  // 获取轮播图数据
  GetBanners().then(res => {
    console.log('轮播图=>', res)
    if (res.data.code == 200) {
      bannersData.value = res.data.data
    }
  })
  // 获取产品中心数据
  GetProductTypeDisplay().then(res => {
    console.log('产品种类=>', res)
    if (res.data.code == 200) {
      ProductTypeDisplay.value = res.data.data.slice(0, 4)
      console.log('ProductTypeDisplay', ProductTypeDisplay.value[0].Id)
      let typeID = ProductTypeDisplay.value[0].Id
      getProductList(typeID,0)
      // GetHomeProductByType({ typeID }).then(res => {
      //   console.log('传res', res)
      //   ProductHomeData.value = res.data.data
      // })
    }
  })

  // 获取案例中心数据
  GetCaseCenter().then(res => {
    console.log('案例中心=>', res)
    if (res.data.code == 200) {
      CaseCenterData.value = res.data.data
    }
  })
  //新闻中心
  GetNewCenter().then(res => {
    console.log('新闻中心=>', res)
    if (res.data.code == 200) {
      NewCenter.value = res.data.data.slice(0, 2)
    }
  })
  // 关于我们
  GetHomeAbout().then(res => {
    console.log('关于我们1=>', res)
    if (res.data.code == 200) {
      HomeAbout.value = res.data.data
    }
  })
  // 合作伙伴
  GetHuoBan().then(res => {
    console.log('合作伙伴=>', res)
    if (res.data.code == 200) {
      HuoBan.value = res.data.data
    }
  })

  nextTick(() => {
    () => AOS.init()
  })

})
// 产品种类table切换
let productTable = ref(0)
// 种类列表
const ProductTypeDisplay = ref()

// 获取产品种类下的列表
const getProductList = (typeID, index) => {
  indexTable.value = index
  productTable.value = index
  GetHomeProductByType({ typeID }).then(res => {
    console.log('传res', res)
    ProductHomeData.value = res.data.data
  })
}

const goNewsDetails = (Id) => {
  router.push({ path: '/news', query: { homeId: Id } })
}

// const goProductMore = (itemId) => {
//   router.push({ path: 'product', query: { itemId } })
// }

// const goMore = (target) => {
//   console.log(target)
//   if (target == 'case') {
//     router.push({ path: 'case' })
//   } else if (target == 'news') {
//     router.push({ path: 'news' })
//   } else if (target == 'about') {
//     router.push({ path: 'contact' })
//   }
// }

// const clickCaseDetail = (id) => {
//   console.log(id)
//   router.push({ path: 'casedetails', query: { userId: id } })
// }
// const clickNewsDetail = (Id) => {
//   console.log(Id)
//   router.push({ path: '/news', query: { homeNews: Id } })
// }



</script>

<style scoped lang="less">
#home {

  // 搜索
  #search {
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 0px 315px;
    background-color: rgba(251, 251, 251, 1);

    .left-list {
      .zl-list {
        margin-right: 40px;
      }

      .zl-list:hover {
        color: #0658C3;
        cursor: pointer;
      }
    }

    ul {
      height: 100%;
      list-style: none;
      display: flex;
      align-items: center;
    }

    .right-search {
      width: 310px;
      height: 40px;
      background: #EEEEEE;
      margin-top: 10px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 12px;

      input {
        background-color: #EEEEEE;
        height: 70%;
        width: 90%;
        border: none;
        outline: none;
      }

      div {
        cursor: pointer;
      }
    }
  }

  #product {
    // background-color: red;
    background-color: rgb(243, 243, 243);

    height: 563px;
    padding: 80px 253px 100px 253px;

    // 产品中心标题
    .titles {
      // margin-top: 80px;
      font-size: 44px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #1D2A46;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // 产品中心列表
    .product-list {
      .proTop {
        margin: 50px 0;

        ul {
          list-style: none;
          display: flex;
          justify-content: space-between;
          padding: 0 65px;
        }

        ul li {
          text-align: left;
          width: 120px;
          padding: 10px 0px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 300;
        }

        ul li:hover {
          color: #0658C3;
          cursor: pointer;
        }

        ul li:last-child {
          text-align: right;
        }

        .productActive {
          color: #0658C3;
          border-bottom: 1px solid #0658C3;
        }
      }

      .list {
        height: 370px;
        // background-color: green;
        padding: 0 65px;
        position: relative;
      }
    }
  }

  // 立即咨询
  .consult {
    // margin-top: 14px;
    height: 182px;
    background-color: #363C48;
    padding: 0px 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .titleText {
      text-align: left;
      width: 390px;
      height: 106px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .button {
      width: 350px;
      height: 80px;
      background-color: #FFFFFF;
      text-align: center;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #007FFF;
      line-height: 80px;
      transform: skew(-30deg);

      span {
        display: inline-block;
        transform: skew(30deg);
      }
    }
  }

  #case {
    height: 631px;
    padding: 80px 253px 100px 253px;


    .caseHover:hover{
      color: #0658C3;
      cursor: pointer;
    }


    // 案例中心标题
    .titles {
      // margin-top: 80px;
      font-size: 44px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #1D2A46;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // 案例中心列表
    .case-list {
      .list {
        margin: 50px 0 22px 0;
        height: 486px;
        // background-color: green;
        padding: 0 65px;
        position: relative;
      }
    }
  }

  // 新闻
  #news {
    height: 538px;
    padding: 80px 253px 50px 253px;
    background-color: rgb(243, 243, 243);

    // background-color: red;
    text-align: left;

    .newsMore:hover {
      color: #0658C3;
      cursor: pointer;
    }



    // 新闻中心标题
    .titles {
      // margin-top: 80px;
      font-size: 44px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #1D2A46;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // 新闻中心列表
    .news-list {
      .list {
        margin: 50px 0 22px 0;
        // background-color: green;
        padding: 0 65px;
        display: flex;
        justify-content: space-between;

        .newslist-content {
          .n-title {
            width: 236px;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #1D2A46;
            // line-height: 30px;
            border-bottom: 1px solid rgba(153, 153, 153, 1);
            padding-bottom: 7px;

          }

          .n-content {
            width: 642px;

            .newsActive:hover {
              background-color: #007FFF;

              p {
                color: #FFFFFF !important;

              }
            }

            // background-color: pink;
            img {
              width: 100%;
              height: 100%;
            }

            .title {
              width: 351px;
              font-size: 18px;
              font-family: PingFang SC;
              font-weight: 800;
              color: #333333;
              line-height: 22px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }

            .title1 {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #999999;
              line-height: 22px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }

          .n-content>div {
            padding: 0px 19px 0px 28px;
          }
        }
      }
    }
  }

  #about {
    // background-color: rgb(243, 243, 243);
    height: 443px;
    padding: 80px 253px 100px 253px;

    // 关于我们
    .titles {
      // margin-top: 80px;
      font-size: 44px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #1D2A46;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .a-about {
      padding: 0 65px;

      display: flex;
      justify-content: space-between;
      text-align: left;
      margin-top: 50px;

      .a-content {
        width: 635px;

        .a-title {
          font-size: 22px;
          font-weight: bold;
          color: #14304C;
          font-family: PingFang SC;
        }

        .a-text {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #666666;
          line-height: 30px;
          text-indent: 2em;
          margin: 40px 0px 73px 0px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
        }

        .a-detail {
          width: 188px;
          height: 50px;
          background: #0658C3;
          border-radius: 10px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
        }
      }

      .a-img {
        width: 581px;
        position: relative;

        .icon-number {
          z-index: 999;
          margin-top: 50px;
          text-align: center;
          width: 86px;
          height: 150px;
          background: #F8FAFF;
          box-shadow: 2px 3px 10px 0px rgba(158, 158, 158, 0.61);
          border-radius: 10px 0px 0px 10px;
          padding: 50px 10px;
          // float: left;
          position: absolute;
          left: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          div:hover {
            cursor: pointer;

            p {
              color: #007FFF;
            }
          }

          p {
            // margin-top: 9px;
            line-height: 14px;
            font-size: 14px;
            font-family: PingFang SC;
            color: #666666;
            line-height: 30px;
          }
        }

        .icon-img {
          cursor: pointer;
          width: 520px;
          height: 350px;
          background: #F8FAFF;
          border-radius: 30px 0px 0px 0px;
          margin-left: 62px;
          overflow: hidden;
          img{
            transition: all 0.5s;

          }
          overflow: hidden;
        }
        .icon-img:hover{
          img{
            transform: scale(1.1);

          }
        }
      }
    }

  }

  // 立即咨询1
  .consult1 {
    // margin-top: 14px;
    height: 182px;
    background: url(../assets/ljzx.png) no-repeat;
    padding: 0px 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .titleText {
      text-align: left;
      width: 390px;
      height: 106px;
      // background-color: red;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .button {
      width: 350px;
      height: 80px;
      background-color: #FFFFFF;
      // transform: skew(-25deg);
      text-align: center;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #007FFF;
      line-height: 80px;
      transform: skew(-30deg);

      span {
        display: inline-block;
        transform: skew(30deg);
      }
    }
  }

  // 合作客户
  #cooperation {
    background-color: rgb(243, 243, 243);
    height: 235px;
    padding: 80px 253px 100px 253px;

    // 合作客户
    .titles {
      // margin-top: 80px;
      font-size: 44px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #1D2A46;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // 合作客户
    .cooperation-list {
      .list {
        margin: 50px 0 22px 0;
        height: 120px;
        // background-color: green;
        padding: 0 65px;
        position: relative;
      }
    }
  }

  // 在线留言
  #message {
    height: 356px;
    padding: 80px 253px 100px 253px;


    // 在线留言
    .titles {
      // margin-top: 80px;
      font-size: 44px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #1D2A46;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .m-content {
      margin: 50px 0 22px 0;
      padding: 0 65px;

      .m-input {
        display: flex;
        justify-content: space-between;

        .el-input {
          width: 326px;
          height: 48px;
        }
      }

      // .el-textarea {
      //     width:1000px;
      //   }

      ::v-deep .el-textarea__inner {
        min-height: 132px !important;
        width: 100%;
      }


      .m-button {
        padding: 0px 420px;
        display: flex;
        justify-content: space-between;

        .reset {
          width: 194px;
          height: 40px;
          line-height: 40px;
          background: #1D2A46;
          border-radius: 10px;
          font-size: 14px;
          font-family: PingFang SC;
          color: #FFFFFF;
        }

        .submit {
          line-height: 40px;
          width: 194px;
          height: 40px;
          background: #0658C3;
          border-radius: 10px;
          font-size: 14px;
          font-family: PingFang SC;
          color: #FFFFFF;
        }
      }
    }

  }


  // 案例中心
  .alBox {
    position: relative;
    height: 546px;
    background-color: #F8FAFF;
    padding: 109px 316px 133px 318px;

    .altitle {
      font-size: 44px;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: #03102C;
    }

    .al-content {
      margin-top: 80px;
      display: flex;
      justify-content: space-between;

      .al-box {
        cursor: pointer;
        width: 400px;
        // height: 424px;
        text-align: left;
        background: #FFFFFF;
        // border: 1px solid #EEEEEE;
        box-shadow: 0px 10px 10px 0px rgba(220, 226, 234, 0.59);
        // background-color: pink;

        .al-img {
          // background-color: orange;
          margin: 10px auto;
          width: 200px;
          height: 200px;

          img {
            width: 100%;
            height: 100%;

          }
        }

        .al-title {
          width: 300px;
          height: 40px;
          margin-top: 14px;
          margin-bottom: 4px;
          margin-left: 30px;
          // background-color: red;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #666666;
          line-height: 21px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .al-text {
          width: 300px;
          height: 40px;
          margin-top: 14px;
          margin-bottom: 43px;
          margin-left: 30px;
          // background-color: red;
          font-size: 14px;
          font-family: PingFang SC;
          color: #999999;
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .al-date {
          width: 137px;
          height: 35px;
          line-height: 35px;
          border: 1px solid #B2C7DC;
          border-radius: 4px;
          text-align: center;
          font-size: 14px;
          font-family: PingFang SC;
          color: #87ABD0;
          margin-left: 30px;
          margin-bottom: 14px;
        }
      }
    }

    .alfixed {
      position: absolute;
      top: 420px;
      right: 132px;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      border: 2px solid #999999;



      img {
        width: 100%;
      }
    }

    .alfixed:hover {
      background-color: #007FFF;
      color: white;
      cursor: pointer;
    }
  }

  // 新闻中心
  .xwBox {
    position: relative;
    padding: 109px 316px 62px 318px;

    .xwtitle {
      font-size: 44px;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: #03102C;
    }

    .xw-content {
      margin-top: 80px;


      .hynew {
        width: 213px;
        height: 48px;
        line-height: 48px;
        text-align: left;
        padding-left: 31px;
        background-color: #007FFF;
        font-family: PingFang SC;
        color: #FFFFFF;
      }

      .gsnew {
        width: 1055px;
        height: 190px;
        // background-color: orange;
        margin: 18px 0px 38px 0px;
        padding: 0px 58px 0px 171px;
        display: flex;
        align-items: center;
        box-shadow: 0px 0px 10px 0px rgba(220, 226, 234, 0.59);

        .gs-content {
          width: 100%;
          height: 124px;
          // background-color: #007FFF;
          text-align: left;
          display: flex;
          align-items: end;

          .gs-img {
            width: 216px;
            height: 124px;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .gs-text {
            width: 640px;
            margin-left: 38px;

            p {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
          }

          .gs-detail {
            width: 90px;
            height: 36px;
            line-height: 36px;
            background: #14304C;
            border-radius: 4px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 800;
            color: #FFFFFF;
            text-align: center;
            margin-left: 74px;
          }

          .gs-detail:hover {
            background: #007FFF;
            cursor: pointer;
          }
        }
      }
    }

    .xwfixed {
      position: absolute;
      top: 495px;
      right: 132px;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      border: 2px solid #999999;

      img {
        width: 100%;
      }
    }

    .xwfixed:hover {
      background-color: #007FFF;
      color: white;
      cursor: pointer;
    }
  }


  // 关于我们
  .gyBox {
    position: relative;
    padding: 109px 319px 110px 317px;

    .gytitle {
      font-size: 44px;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: #03102C;
    }

    .gy-content {
      margin-top: 89px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-img {
        width: 516px;
        height: 356px;

        // background-color: rgb(44, 207, 236);
        img {
          width: 100%;
          height: 100%;
        }
      }

      .right-text {
        text-indent: 2em;
        width: 738px;
        // height: 257px;
        text-align: left;
        // background-color: aquamarine;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #666666;
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 9;
      }
    }

    .gyfixed {

      position: absolute;
      top: 372px;
      right: 132px;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      border: 2px solid #999999;

      img {
        width: 100%;
      }
    }

    .gyfixed:hover {
      background-color: #007FFF;
      color: white;
      cursor: pointer;
    }
  }



}</style>

