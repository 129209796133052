<template>
  <!-- 初始化 -->
  <div class="swiper-product4" v-if="props.indexTable == 4">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in props.ProductHomeData" :key="index" @click="goProductDetail(item.Id)">
        <div class="box">
          <div style="width: 382px;height: 282px;margin:15px auto 20px;">
            <img :src="item.MainImgUrl" alt="" style="width: 100%;height: 100%;">
          </div>
          <div class="slide-text">
            {{ item.Title }}
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-next product-next4"></div>
    <div class="swiper-button-prev product-prev4"></div>
  </div>
  <!-- 产品1 -->
  <div class="swiper-product" v-if="props.indexTable == 0">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in props.ProductHomeData" :key="index" @click="goProductDetail(item.Id)">
        <div class="box">
          <div style="width: 382px;height: 282px;margin:15px auto 20px;">
            <img :src="item.MainImgUrl" alt="" style="width: 100%;height: 100%;">
          </div>
          <div class="slide-text">
            {{ item.Title }}
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-next product-next"></div>
    <div class="swiper-button-prev product-prev"></div>
  </div>
  <!-- 产品2 -->
  <div class="swiper-product1" v-if="props.indexTable == 1">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in props.ProductHomeData" :key="index" @click="goProductDetail(item.Id)">
        <div class="box">
          <div style="width: 382px;height: 282px;margin:15px auto 20px;">
            <img :src="item.MainImgUrl" alt="" style="width: 100%;height: 100%;">
          </div>
          <div class="slide-text">
            {{ item.Title }}
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-next product-next1"></div>
    <div class="swiper-button-prev product-prev1"></div>
  </div>
  <!-- 产品3 -->
  <div class="swiper-product2" v-if="props.indexTable == 2">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in props.ProductHomeData" :key="index" @click="goProductDetail(item.Id)">
        <div class="box">
          <div style="width: 382px;height: 282px;margin:15px auto 20px;">
            <img :src="item.MainImgUrl" alt="" style="width: 100%;height: 100%;">
          </div>
          <div class="slide-text">
            {{ item.Title }}
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-next product-next2"></div>
    <div class="swiper-button-prev product-prev2"></div>
  </div>
  <!-- 产品4 -->
  <div class="swiper-product3" v-if="props.indexTable == 3">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in props.ProductHomeData" :key="index" @click="goProductDetail(item.Id)">
        <div class="box">
          <div style="width: 382px;height: 282px;margin:15px auto 20px;">
            <img :src="item.MainImgUrl" alt="" style="width: 100%;height: 282px;">
          </div>
          <div class="slide-text">
            {{ item.Title }}
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-next product-next3"></div>
    <div class="swiper-button-prev product-prev3"></div>
  </div>
</template>

<script setup>
// 引入vue函数
import { onMounted, defineProps, ref, watch } from 'vue';

// 引入轮播图js文件
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import router from '@/router';
// 接收数据
const props = defineProps(['ProductHomeData', 'indexTable'])
let num = ref(3)
watch(() => props.ProductHomeData, (newValue, oldValue) => {
  console.log(newValue,'newValue')
  if (newValue&&newValue.length < 3) {
    num.value = newValue.length
  }else{
    num.value = 3
  }
  banner()
  banner1()
  banner2()
  banner3()
  // 处理轮播图数据
  console.log('newValue,oldValue', newValue)
})

// 初始化轮播图
const banner = () => {
  setTimeout(() => {
    var mySwiper2 = new Swiper('.swiper-product', {
      loop:num.value==3, // 循环模式选项
      // loopPreventsSlide: true,
      speed: 500,
      slidesPerView:3,
      spaceBetween: 30,
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".product-next",
        prevEl: ".product-prev"
      }
    });
  }, 0);
}
const banner4 = () => {
  console.log('num.value',num.value)
  setTimeout(() => {
    var mySwiper2 = new Swiper('.swiper-product4', {
      loop:num.value==3, // 循环模式选项
      // loopPreventsSlide: true,
      speed: 500,
      // speed: 1000,
      slidesPerView: 3,
      spaceBetween: 30,
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".product-next4",
        prevEl: ".product-prev4"
      }
    });
  }, 500);
}
const banner1 = () => {
  setTimeout(() => {
    var mySwiper2 = new Swiper('.swiper-product1', {
      loop: num.value==3, // 循环模式选项
      // loopPreventsSlide: true,
      speed: 500,
      // speed: 1000,
      slidesPerView: 3,
      spaceBetween: 30,
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".product-next1",
        prevEl: ".product-prev1"
      }
    });
  }, 0);
}
const banner2 = () => {
  setTimeout(() => {
    var mySwiper2 = new Swiper('.swiper-product2', {
      loop: num.value==3, // 循环模式选项
      // loopPreventsSlide: true,
      speed: 500,
      // speed: 1000,
      slidesPerView: 3,
      spaceBetween: 30,
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".product-next2",
        prevEl: ".product-prev2"
      }
    });
  }, 0);
}
const banner3 = () => {
  setTimeout(() => {
    var mySwiper2 = new Swiper('.swiper-product3', {
      loop: num.value==3, // 循环模式选项
      // loopPreventsSlide: true,
      speed: 500,
      // speed: 1000,
      slidesPerView: 3,
      spaceBetween: 30,
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".product-next3",
        prevEl: ".product-prev3"
      }
    });
  }, 0);
}
onMounted(() => {
  // 初始化轮播图
  banner4()
})

const goProductDetail = (userId)=>{
  // console.log('产品ID',userId)
  router.push({path:'/product/productdetails',query:{userId}})
}


</script>


<style scoped lang="less">
// 轮播图
.swiper-product {
  // background-color: red;
  height: 370px;
  width: 100%;
  overflow: hidden;
  padding: 5px;
}

.swiper-product1 {
  // background-color: red;
  height: 370px;
  width: 100%;
  overflow: hidden;
  padding: 5px;
}

.swiper-product2 {
  // background-color: red;
  height: 370px;
  width: 100%;
  overflow: hidden;
  padding: 5px;
}

.swiper-product3 {
  // background-color: red;
  height: 370px;
  width: 100%;
  overflow: hidden;
  padding: 5px;
}

.swiper-product4 {
  // background-color: red;
  height: 370px;
  width: 100%;
  overflow: hidden;
  padding: 5px;
}





.swiper-button-next,
.swiper-button-prev {
  width: 44px;
  height: 48px;
  background: #000000;
  opacity: 0.2;
  color: rgba(255, 255, 255, 1);
}

.swiper-button-next {
  position: absolute;
  right: 0px;
}

.carousel-img {
  width: 100%;
  height: 100%;
}



.swiper-slide {
  .box {
    width: 382px;
    height: 370px;

    .slide-text {
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 46px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    overflow: hidden;
    img{
    transition: all 0.5s;
    width: 100%!important;
    height: 100%!important;
    object-fit:contain!important;

    }
    img:hover{
          transform: scale(1.1);
        }
  }

  .box:hover {
    box-shadow: 0px 4px 20px 0px rgba(158, 158, 158, 0.26);
    cursor: pointer;
  }

  text-align: center;
  font-size: 18px;
  background: #fff;




  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;

}
</style>
