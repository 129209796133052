<template>
  <div class="swiper-cooperation">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item,index) in props.HuoBan" :key="index">
      <img :src="item.ImgUrl" style="width: 100%;height: 100%;" alt="">
      </div>
    </div>


  </div>
  <div class="swiper-button-next cooperation-next"></div>
  <div class="swiper-button-prev cooperation-prev"></div>
</template>

<script setup>
// 引入vue函数
import { onMounted, defineProps, ref,watch } from 'vue';

// 引入轮播图js文件
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
// 接收数据
const props = defineProps(['HuoBan'])
let num = ref(5)
watch(() => props.HuoBan, (newValue, oldValue) => {
  console.log('合作',newValue.length)
  if (newValue.length < 5) {
    num.value = newValue.length
  }else{
    num.value = 5
  }

  // 处理轮播图数据
  console.log('newValue,oldValue', newValue)
})


// 初始化轮播图
const bannerCase = () => {
  setTimeout(() => {
    var mySwiper3 = new Swiper('.swiper-cooperation', {
      loop:num.value==5, // 循环模式选项
      // loopPreventsSlide: false,
      speed: 500,
      slidesPerView: 5,
      spaceBetween: 30,

      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".cooperation-next",
        prevEl: ".cooperation-prev"
      },

    });
  }, 500);
}


onMounted(() => {
      // 获取轮播图数据
      bannerCase()
})
</script>


<style scoped lang="less">
// 轮播图
.swiper-cooperation {
  // background-color: red;
  height: 120px;
  width: 100%;
  overflow: hidden;
  padding: 5px;
}


.swiper-button-next,
.swiper-button-prev {
  width: 44px;
  height: 48px;
  background: #000000;
  opacity: 0.2;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.swiper-button-next {
  position: absolute;
  right: 0px;
}


.carousel-img {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  box-shadow: 0px 4px 10px 0px rgba(158, 158, 158, 0.26);
  overflow: hidden;
  img{
    transition: all 0.5s;
  }

  img:hover{
    transform: scale(1.2);
  }

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  // object-fit: cover;

}
</style>
