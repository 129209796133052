import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: "首页",
    },
    component: HomePage
  },
  {
    path: '/saas',
    name: 'Saas',
    meta: {
      title: "Saas平台",
    },
    component: () => import('../views/SaasPage.vue'),
  },
  {
    path: '/product',
    name: 'product',
    meta: {
      title: "产品",
    },
    component: () => import('../views/product/ProductPage.vue'),
     children: [
      {
        path: 'productlist',
        name: 'ProductList',
        meta: {
          title: "产品展示",
        },
        component: () => import('../views/product/ProductList.vue')
      },
      {
        path: 'productdetails',
        name: 'ProductDetails',
        meta: {
          title: "产品详情",
        },
        component: () => import('../views/product/ProductDetails.vue')
      }
    ]
  },
  {
    path: '/news',
    name: 'news',
    meta: {
      title: "新闻资讯",
    },
    component: () => import('../views/news/NewsPage.vue'),
    children: [
      {
        path: 'newslist',
        name: 'NewsList',
        meta: {
          title: "新闻列表",
        },
        component: () => import('../views/news/NewsList.vue')
      },
      {
        path: 'details',
        name: 'Details',
        meta: {
          title: "新闻详情",
        },
        component: () => import('../views/news/NewsDetails.vue')
      }
    ]
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/case/CasePage.vue'),
    children: [
      {
        path: 'caselist',
        name: 'CaseList',
        component: () => import('../views/case/CaseList.vue')
      },
      {
        path: 'casedetails',
        name: 'CaseDetails',
        component: () => import('../views/case/CaseDetails.vue')
      }
    ]
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactPage.vue')
  },
  {
    path: '/onlinemsg',
    name: 'onlinemsg',
    component: () => import('../views/OnlineMsgPage.vue')
  },
  {
    path: '/productdetails',
    name: 'productdetails',
    component: () => import('../views/product/ProductDetails.vue')
  },
  {
    path: '/casedetails',
    name: 'casedetails',
    component: () => import('../views/case/CaseDetails.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
